import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container as BlockContainer } from '../components/Blocks'
import { Container, Row, Col } from 'reactstrap'

const TermsAndCondition = () => {
    return (
        <Layout>
            <SEO title="Terms and Condition" keywords={[`gatsby`, `application`, `react`]} />
            <BlockContainer center={false} className="mt-5">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h1 className="text-center">Terms and Condition</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
                                Sorosoro Ibaba Development Cooperative (SIDC) operates this website to provide online access to information about SIDC and the products, services and opportunities we offer. Your
                                access and use of this website is subject to the following terms and conditions and constitutes
                                acceptance of these terms (“Terms of Use”).
                            </p>
                            <p>
                                SIDC reserves the right to modify these Terms of Use at any time without giving you prior
                                notice. Your use of the website following any such modification constitutes your agreement
                                to follow and be bound by the Terms of Use as modified.
                            </p>
                            <p>
                                The term “SIDC” or “us” or “we” refers to the owner of the website. The term “you” refers to
                                the user or viewer of the website.
                            </p>
                            <p>
                                The use of this website is subject to the following Terms of Use:
                            </p>
                            <ul>
                                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                <li>SIDC does not warrant that the functions performed by the website will be uninterrupted, timely, secure or error-free or that the defects on the website will be corrected.</li>
                                <li>In no event will SIDC be liable for any damage, including, but not limited to any direct, incidental, consequential, special, exemplary or other indirect damages arising out of the use of the website or the content and any transactions conducted through or facilitated by the website.</li>
                                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                                <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
                                <li>Unauthorized use of this website may result in a claim for damages and/or be a criminal offence.</li>
                                <li>From time to time this website may also include links or advertisements to other websites. These links are provided for your convenience to provide further information or they may advertise products and services of other companies or parties. We have no responsibility for the content of the linked website(s) or the content of any advertisements.</li>
                                <li>Your use of this website and any dispute arising out of such use of the website is subject to the Laws of the Philippines</li>
                                <li>This site is intended /solely for users who are eighteen (18) years of age or older. Any registration by, use of or access to the website by anyone under 18 is unauthorized and in violation of these Terms and Conditions of Use.</li>
                                <li>By using our service or this website, you represent and warrant that you are 18 or older and that you agree to and abide by all of the terms and conditions of this agreement.</li>
                                <li>The content of this website may NOT be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted or sold in any form or by any means, in whole or in part, without our written permission.</li>
                            </ul>
                            <h3>Contact Details</h3>
                            <p>Sorosoro Ibaba Development Cooperative</p>
                            <p>Sorosoro Ibaba Batangas City</p>
                            <p>Email: sidcnet@yahoo.com</p>
                            <p>Phone: + 63 (0)300 4058</p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        </Layout>
    )
}

export default TermsAndCondition;
